import { Rive, Layout } from "@rive-app/canvas";

const riveScrollAnimation = {
    init() {
        const elements = document.querySelectorAll('[data-rive-scroll]');
        if (elements.length === 0) return;
        elements.forEach(element => {
            const options = element.dataset.options ? JSON.parse(element.dataset.options) : {};
            const canvas = element.querySelector('canvas');
            if (!canvas) return;
            const riveInstance = new Rive({
                canvas: canvas,
                layout: new Layout({ fit: 'FitWidth', alignment: 'center' }),
                ...options,
                onLoad: () => {
                    element.classList.add('on-load');
                    riveInstance.resizeDrawingSurfaceToCanvas();
                }
            });
            let hasPlayed = false;
            const observerOptions = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !hasPlayed) {
                        riveInstance.play();
                        hasPlayed = true;
                        observer.unobserve(entry.target);
                    }
                });
            }, observerOptions);
            observer.observe(element);
        });
    }
};

export default riveScrollAnimation;