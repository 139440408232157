class VideoManager {
    constructor() {
        this.videoPlayers = [];
        this.pendingVideos = [];
        this.youtubeApiLoaded = false;
    }

    addVideo(videoElement) {
        if (!this.pendingVideos.includes(videoElement)) {
            this.pendingVideos.push(videoElement);
        }
    }

    initializeVideos() {
        this.pendingVideos.forEach(video => this.initializeVideo(video));
        this.pendingVideos = [];
    }

    initializeVideo(videoElement) {
        const uniqueId = 'video_' + Math.random().toString(36).substr(2, 9);
        videoElement.dataset.uniqueId = uniqueId;


        const videoType = videoElement.dataset.videoType;
        const displayType = videoElement.dataset.displayType;

        if (videoType === 'mp4') {
            this.initializeMp4Video(videoElement, uniqueId);
        } else if (videoType === 'youtube') {
            this.initializeYouTubeVideo(videoElement, uniqueId, displayType);
        }

        this.setupCustomControls(videoElement);
        this.setVideoSize(videoElement);
    }

    initializeMp4Video(videoElement, uniqueId) {
        const videoPlayer = document.createElement('video');
        videoPlayer.id = uniqueId;
        videoPlayer.playsInline = true;

        const shouldAutoplay = videoElement.dataset.autoplay === 'true';
        videoPlayer.muted = shouldAutoplay;
        videoPlayer.autoplay = shouldAutoplay;
        videoPlayer.loop = true;

        const sources = JSON.parse(videoElement.dataset.sources || '[]');
        
        this.setVideoSource(videoPlayer, sources);

        videoElement.appendChild(videoPlayer);

        this.addResizeListener(videoElement, videoPlayer, sources);

        this.videoPlayers.push({
            id: uniqueId,
            player: videoPlayer,
            type: 'mp4'
        });

        if (shouldAutoplay) {
            this.playMp4Video(videoPlayer);
            videoElement.classList.add('is-playing');
            videoElement.classList.add('is-muted');
        } else {
            videoElement.classList.add('is-paused');
        }
    }

    initializeYouTubeVideo(videoElement, uniqueId, displayType) {
        const videoId = videoElement.dataset.youtubeId;
        const shouldAutoplay = videoElement.dataset.autoplay === 'true';

        if (displayType === 'popup') {
            this.createYouTubePopupTrigger(videoElement, videoId, uniqueId);
        } else {
            const placeholder = document.createElement('div');
            placeholder.id = uniqueId;
            videoElement.appendChild(placeholder);

            this.loadYouTubeApi(() => {
                this.createYouTubePlayer(videoElement, videoId, uniqueId, shouldAutoplay, displayType);
            });
        }
    }

    loadYouTubeApi(callback) {
        if (this.youtubeApiLoaded) {
            callback();
        } else if (window.YT && window.YT.Player) {
            this.youtubeApiLoaded = true;
            callback();
        } else {
            console.log('loadYouTubeApi');
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            window.onYouTubeIframeAPIReady = () => {
                console.log('onYouTubeIframeAPIReady');
                this.youtubeApiLoaded = true;
                callback();
            };
        }
    }

    createYouTubePlayer(videoElement, videoId, uniqueId, shouldAutoplay, displayType) {
        const isBackground = displayType === 'background';
        const isPopup = displayType === 'popup';

        const playerVars = {
            autoplay: shouldAutoplay ? 1 : 0,
            mute: isBackground ? 1 : 0, // Tắt tiếng chỉ cho background
            controls: isBackground ? 0 : 1, // Ẩn controls chỉ cho background
            rel: 0,
            modestbranding: 1,
            loop: isBackground ? 1 : 0, // Lặp chỉ cho background
            playlist: isBackground ? videoId : undefined, // Cần thiết cho loop với single video
            playsinline: 1,
            showinfo: isBackground ? 0 : 1,
            disablekb: isBackground ? 1 : 0,
            fs: isBackground ? 0 : 1,
            iv_load_policy: isBackground ? 3 : 1,
            autohide: isBackground ? 1 : 2
        };

        const player = new YT.Player(uniqueId, {
            height: '100%',
            width: '100%',
            videoId: videoId,
            playerVars: playerVars,
            events: {
                'onReady': (event) => {
                    if (shouldAutoplay) {
                        event.target.playVideo();
                        videoElement.classList.add('is-playing');
                        if (isBackground) {
                            videoElement.classList.add('is-muted');
                        }
                    } else {
                        videoElement.classList.add('is-paused');
                    }
                    
                    if (isBackground) {
                        this.setBackgroundVideoSize(event.target, videoElement);
                        
                        // Thêm trình lắng nghe sự kiện resize với debounce
                        let resizeTimeout;
                        const resizeHandler = () => {
                            clearTimeout(resizeTimeout);
                            resizeTimeout = setTimeout(() => {
                                this.setBackgroundVideoSize(event.target, videoElement);
                            }, 250);
                        };
                        window.addEventListener('resize', resizeHandler);
                        
                        // Lưu trình lắng nghe để có thể gỡ bỏ sau này nếu cần
                        videoElement.dataset.resizeHandler = resizeHandler;
                    }
                },
                'onStateChange': (event) => {
                    if (isBackground) {
                        if (event.data === YT.PlayerState.PLAYING) {
                            // Gọi setBackgroundVideoSize mỗi khi video bắt đầu phát
                            this.setBackgroundVideoSize(event.target, videoElement);
                        }
                        if (event.data === YT.PlayerState.ENDED) {
                            event.target.playVideo(); // Phát lại chỉ cho background video
                        }
                    }
                }
            }
        });

        this.videoPlayers.push({
            id: uniqueId,
            player: player,
            type: 'youtube',
            displayType: displayType
        });

        return player;
    }

    setBackgroundVideoSize(player, videoElement) {
        const container = videoElement;
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        // Lấy tỷ lệ khung hình video từ thuộc tính data, mặc định là 16:9
        const videoAspectRatio = videoElement.dataset.ratio ? parseFloat(videoElement.dataset.ratio) : 16/9;

        let newWidth, newHeight;

        // Tính toán kích thước mới để video lấp đầy container và giữ tỷ lệ khung hình
        if (containerWidth / containerHeight > videoAspectRatio) {
            newWidth = containerWidth;
            newHeight = containerWidth / videoAspectRatio;
        } else {
            newHeight = containerHeight;
            newWidth = containerHeight * videoAspectRatio;
        }

        // Lấy iframe trực tiếp
        const iframe = player.getIframe();

        // Cập nhật kích thước của iframe
        iframe.width = Math.ceil(newWidth);
        iframe.height = Math.ceil(newHeight);

        // Điều chỉnh style của iframe
        iframe.style.width = `${Math.ceil(newWidth)}px`;
        iframe.style.height = `${Math.ceil(newHeight)}px`;
        iframe.style.position = 'absolute';
        iframe.style.top = '50%';
        iframe.style.left = '50%';
        iframe.style.transform = 'translate(-50%, -50%)';

        // Cập nhật kích thước của player (điều này có thể không cần thiết, nhưng giữ để đảm bảo)
        player.setSize(Math.ceil(newWidth), Math.ceil(newHeight));
    }

    createYouTubePopupTrigger(videoElement, videoId, uniqueId) {
        const triggerButton = videoElement.querySelector('[data-video-play-element]');
        if (triggerButton) {
            triggerButton.addEventListener('click', () => {
                this.openYouTubePopup(videoId, uniqueId, videoElement);
            });
        }
    }

    openYouTubePopup(videoId, uniqueId, videoElement) {
        let popup = document.querySelector('.youtube-popup');
        if (!popup) {
            popup = document.createElement('div');
            popup.classList.add('youtube-popup');
            popup.innerHTML = `
                <span class="overlay" data-youtube-popup-close></span>
                <div class="youtube-popup-content">
                    <div data-video-content>
                        <div id="${uniqueId}"></div>
                    </div>
                    <button class="cta-youtube-popup-close" data-youtube-popup-close><span class="icon-close"></span></button>
                </div>
            `;
            document.body.appendChild(popup);
            
            const closeElements = popup.querySelectorAll('[data-youtube-popup-close]');
            closeElements.forEach((el) => {
                el.addEventListener('click', () => {
                    this.closeYouTubePopup(popup, uniqueId, videoElement);
                });
            });
        }
        
        popup.classList.add('on');
        
        const player = this.videoPlayers.find(p => p.id === uniqueId);
        if (player) {
            player.player.playVideo();
        } else {
            this.loadYouTubeApi(() => {
                this.createYouTubePlayer(popup, videoId, uniqueId, true, 'popup');
            });
        }
    }

    closeYouTubePopup(popup, uniqueId, videoElement) {
        popup.classList.remove('on');
        const player = this.videoPlayers.find(p => p.id === uniqueId);
        if (player) {
            player.player.pauseVideo();
        }
        videoElement.classList.remove('is-playing');
        videoElement.classList.add('is-paused');
    }

    setupCustomControls(videoElement) {
        const playButton = videoElement.querySelector('[data-video-play-element]');
        const soundButton = videoElement.querySelector('[data-video-mute-element]');

        if (playButton) {
            playButton.addEventListener('click', () => this.togglePlayPause(videoElement));
        }

        if (soundButton) {
            soundButton.addEventListener('click', () => this.toggleMute(videoElement));
        }
    }

    togglePlayPause(videoElement) {
        const player = this.getPlayerByElement(videoElement);
        if (!player) return;

        if (player.type === 'mp4') {
            if (player.player.paused) {
                player.player.play();
                videoElement.classList.remove('is-paused');
                videoElement.classList.add('is-playing');
            } else {
                player.player.pause();
                videoElement.classList.remove('is-playing');
                videoElement.classList.add('is-paused');
            }
        } else if (player.type === 'youtube') {
            const state = player.player.getPlayerState();
            if (state === YT.PlayerState.PLAYING) {
                player.player.pauseVideo();
                videoElement.classList.remove('is-playing');
                videoElement.classList.add('is-paused');
            } else {
                player.player.playVideo();
                videoElement.classList.remove('is-paused');
                videoElement.classList.add('is-playing');
            }
        }
    }

    toggleMute(videoElement) {
        const player = this.getPlayerByElement(videoElement);
        if (!player) return;

        if (player.type === 'mp4') {
            player.player.muted = !player.player.muted;
            videoElement.classList.toggle('is-muted', player.player.muted);
        } else if (player.type === 'youtube') {
            if (player.player.isMuted()) {
                player.player.unMute();
                videoElement.classList.remove('is-muted');
            } else {
                player.player.mute();
                videoElement.classList.add('is-muted');
            }
        }
    }

    getPlayerByElement(videoElement) {
        const uniqueId = videoElement.dataset.uniqueId;
        return this.videoPlayers.find(p => p.id === uniqueId);
    }

    playMp4Video(videoPlayer) {
        videoPlayer.play().catch(error => {
            console.error('Autoplay was prevented:', error);
        });
    }

    setVideoSource(videoPlayer, sources) {
        const windowWidth = window.innerWidth;
        const appropriateSource = sources.find(source => 
            windowWidth >= source.minWidth && windowWidth < source.maxWidth
        );
        
        if (appropriateSource && videoPlayer.src !== appropriateSource.src) {
            const currentTime = videoPlayer.currentTime;
            const isPlaying = !videoPlayer.paused;
            videoPlayer.src = appropriateSource.src;
            videoPlayer.load();
            videoPlayer.currentTime = currentTime;
            if (isPlaying) {
                videoPlayer.play();
            }
        }
    }

    setVideoSize(videoElement) {
        const container = videoElement;
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        const player = this.getPlayerByElement(videoElement);

        if (!player) return;

        if (player.type === 'mp4') {
            const videoAspectRatio = videoElement.dataset.ratio ? parseFloat(videoElement.dataset.ratio) : 16/9;
            let newWidth, newHeight;
            newWidth = containerHeight * videoAspectRatio;
            newHeight = containerHeight;
            if (newWidth < containerWidth) {
                newWidth = containerWidth;
                newHeight = containerWidth / videoAspectRatio;
            }
            player.player.style.width = `${newWidth}px`;
            player.player.style.height = `${newHeight}px`;

            const screenWidth = window.innerWidth;
            const sources = JSON.parse(videoElement.dataset.sources || '[]');
            const matchedSource = sources.find(
                (source) => screenWidth >= source.minWidth && screenWidth < source.maxWidth
            );
            if (matchedSource && matchedSource.src !== player.player.src) {
                player.player.src = matchedSource.src;
                player.player.load();
            }
        } else if (player.type === 'youtube') {
            player.player.setSize(containerWidth, containerHeight);
        }
    }

    addResizeListener(videoElement, videoPlayer, sources) {
        let resizeTimeout;
        const resizeHandler = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                this.setVideoSize(videoElement);
                this.setVideoSource(videoPlayer, sources);
            }, 250); // Debounce resize event
        };

        window.addEventListener('resize', resizeHandler);

        // Initial call to set the correct size
        resizeHandler();
    }
}

export const videoManager = new VideoManager();