const loadFile = async function (moduleFileName) {
  const loader = await import(/* webpackChunkName: "[request]" */ `../../modules/${moduleFileName}.js`);
  return loader;
};

const lazyLoadModule = (moduleFileName, timer, options) => {
  const myPromise = new Promise((resolve, reject) => {
    let loaded = false;
    const init = function () {
      // console.log(loaded);
      if (!loaded) {
        loadFile(moduleFileName).then((LoadedModule) => {
          console.log('Loading Module:', moduleFileName);
          LoadedModule.init(options).then(() => {
            // console.log('module loaded');
            setTimeout(() => {
              resolve();
            }, 50);
          });
        });
      }
      loaded = true;
    };

    function initOnEvent(event) {
      // console.log('initOnEvent loaded', loaded);
      if (!loaded) {
        init();
      }
      // console.log(event.type, event.currentTarget);
      event.currentTarget.removeEventListener('scroll', initOnEvent, {
        once: true
      });
      event.currentTarget.removeEventListener('mousemove', initOnEvent, {
        once: true
      });
      event.currentTarget.removeEventListener('touchstart', initOnEvent, {
        once: true
      });
      // event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
    }
    // console.log('loaded before addEventListener', loaded);
    if (!loaded) {
      document.addEventListener('scroll', initOnEvent);
      document.addEventListener('mousemove', initOnEvent);
      document.addEventListener('touchstart', initOnEvent);
      document.addEventListener(
        'DOMContentLoaded',
        () => {
          console.log('DOMContentLoaded');
          if (!loaded) {
            setTimeout(() => {
              init();
            }, timer);
          }
        },
        { once: true }
      );
    }
  });

  return myPromise;
};

export default lazyLoadModule;
