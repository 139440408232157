import '../css/main.css';
import lazyLoadModule from './utils/loaders/moduleLoader.js';
import riveScrollAnimation from './components/riveScrollAnimation.js';
import { initializeVideos } from './components/videoInit.js';
import swiper from './components/swiper.js';

lazyLoadModule('addTableWrapper', 3000).then(() => {});
lazyLoadModule('alpineComponents', 3000).then(() => {
  console.log('alpineComponents has been loaded');
});
lazyLoadModule('lazyLoadImages', 3000).then(() => {
  console.log('lazyLoadImages has been loaded');
});
lazyLoadModule('addScrollToSection', 0).then(() => {});
riveScrollAnimation.init();

document.addEventListener('DOMContentLoaded', function() {
  document.body.classList.add('swiper-load');
  initializeVideos();
  swiper.init();
});

if (import.meta.hot) {
  // HMR code
  import.meta.hot.accept();
}