import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs, EffectFade } from 'swiper/modules';
import 'swiper/css/bundle';

var swiper = {
  swiperInstances: {},
  init: function() {
    if (typeof Swiper === 'undefined') {
      console.error('Swiper is not loaded. Make sure to include Swiper library before this script.');
      return;
    }
    this.initAllSwipers();
    this.setupResizeListener();
    this.handleIpadSwiper();
  },
  createSwiper: function(element, name, options, pagination) {
    const customPagination = document.querySelector(`.swiper-custom-pagination[data-name="${name}"]`);
    const useCustomPagination = element.dataset.progress === 'true' && customPagination;

    const updateCustomPagination = function(swiper) {
      if (useCustomPagination) {
        const fractionEl = customPagination.querySelector('.swiper-fraction');
        let currentIndex = swiper.realIndex + 1;
        
        if (swiper.isEnd) {
          currentIndex = swiper.slides.length;
        }
        
        fractionEl.textContent = `${currentIndex} / ${swiper.slides.length}`;
      }
    };

    const initCustomPagination = function(swiper) {
      if (useCustomPagination) {
        const allSlidesVisible = swiper.isEnd && swiper.activeIndex === 0;
        
        if (allSlidesVisible) {
          customPagination?.classList.add('off');
        } else {
          customPagination?.classList.remove('off');
        }
      }
    }

    let sw_thumbs = null;
    const elementData = element.dataset;

    if (elementData.thumbs) {
        const elementThumbs = document.querySelector(`[data-swiper-thumbs][data-name="thumbs-${name}"]`);
        if(elementThumbs) {
          const optionsThumbs = elementThumbs.dataset.options ? JSON.parse(elementThumbs.dataset.options) : {};

          const thumbsSwiper = new Swiper(elementThumbs, {
              modules: [Navigation, Pagination, Autoplay, Thumbs, EffectFade],
              ...optionsThumbs,
          });

          sw_thumbs = {
              swiper: thumbsSwiper
          };
        }
    }

    const slider = new Swiper(element, {
      modules: [Navigation, Pagination, Autoplay, Thumbs, EffectFade],
      watchSlidesProgress: true,
      speed: 1000,
      observer: true,
      grabCursor: true,
      navigation: {
        nextEl: `[data-swiper-next][data-name="${name}"]`,
        prevEl: `[data-swiper-prev][data-name="${name}"]`,
      },
      pagination: useCustomPagination ? {
        el: customPagination.querySelector('.swiper-progress-bar'),
        type: 'progressbar',
      } : {
        el: pagination,
        clickable: true,
        renderBullet: function(index, className) {
          return `<span class="${className}">${index + 1}</span>`;
        },
      },
      thumbs: sw_thumbs,
      on: {
        init: function(swiper) {
          document.querySelector(`[data-swiper-next][data-name="${name}"]`)?.classList.add("on");
          document.querySelector(`[data-swiper-prev][data-name="${name}"]`)?.classList.add("on");

          if (useCustomPagination) {
            customPagination?.classList.add('w-init');
            initCustomPagination(swiper);
            updateCustomPagination(swiper);
          }
        },
        afterInit: function(swiper) {
          if(window.EqualHeightElements) {  
            window.EqualHeightElements.refresh(name);
          }
        },
        slideChange: function(swiper) {
          if (useCustomPagination) {
            updateCustomPagination(swiper);
          }

          if(name == "swiper_images") {
            let num = swiper.realIndex;
            if(window.innerWidth > 767) {
                const timelineSlides = document.querySelectorAll('.timeline-slider .swiper-slide');
                
                timelineSlides.forEach(slide => {
                    slide.classList.remove('on');
                });
                
                if(timelineSlides[num]) {
                    timelineSlides[num].classList.add('on');
                }
            }
          }
        },
        progress: function(swiper, progress) {
          updateCustomPagination(swiper);
        },
        setTranslate: function(swiper, translate) {
          updateCustomPagination(swiper);
        },
        resize: function(swiper) {
          updateCustomPagination(swiper);
          initCustomPagination(swiper);
        }
      },
      ...options,
    });

    return slider;
  },

  initializeOrDestroySwiper: function(element) {
    if (!element.querySelector('.swiper-slide')) return;
    
    const name = element.dataset.name;
    const options = JSON.parse(element.dataset.options || '{}');
    const pagination = element.dataset.pagination ? `.swiper-pagination[data-name="${name}"]` : null;
    let destroyWidth = element.dataset.destroy;

    

    if (destroyWidth) {
      destroyWidth = parseInt(element.dataset.destroy) || 0;
      if (window.innerWidth < destroyWidth) {
        if (!this.swiperInstances[name]) {
          this.swiperInstances[name] = this.createSwiper(element, name, options, pagination);
        }
      } else {
        if (this.swiperInstances[name]) {
          this.swiperInstances[name].destroy(true, true);
          delete this.swiperInstances[name];
        }
      }
    } else {
      if (!this.swiperInstances[name]) {
        this.swiperInstances[name] = this.createSwiper(element, name, options, pagination);
      }
    }
  },

  initAllSwipers: function() {
    document.querySelectorAll("[data-swiper]").forEach((element) => {
      this.initializeOrDestroySwiper(element);
    });  
  },

  setupResizeListener: function() {
    let resizeTimeout;
    window.addEventListener('resize', () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      this.initAllSwipers();
    }, 250); // Debounce resize event to improve performance
    });
  },

  reinitialize: function(element) {

      const name = element.dataset.name;

      if (this.swiperInstances[name]) {
        this.swiperInstances[name].destroy(true, true);
        delete this.swiperInstances[name];
      }

      this.initializeOrDestroySwiper(element);
  },
  handleIpadSwiper: function() {
    const timelineSlides = document.querySelectorAll('.timeline-slider .swiper-slide');
    const swiperImagesEl = document.querySelector('[data-swiper][data-name="swiper_images"]');
    
    const handleSlideChange = (index) => {
        if (swiperImagesEl && swiperImagesEl.classList.contains('swiper')) {
            const swiperImages = swiperImagesEl.swiper;
            if (swiperImages) {
                swiperImages.slideTo(index);
            }
        }
    };

    timelineSlides.forEach((slide, index) => {
        
        
        slide.querySelector('[data-square-icon]').addEventListener('mouseenter', () => {
            if (window.innerWidth >= 768) {
                handleSlideChange(index);
                
                timelineSlides.forEach(s => s.classList.remove('on'));
                slide.classList.add('on');
            }
        });
    });
  }
};

export default swiper;